import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-description',
  templateUrl: './zeus-description.component.html',
  styleUrls: ['./zeus-description.component.scss']
})
export class ZeusDescriptionComponent implements OnInit {


  @Output() goToForm = new EventEmitter();

  onClickOnGoToForm(){
    this.goToForm.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
