import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-usability',
  templateUrl: './zeus-usability.component.html',
  styleUrls: ['./zeus-usability.component.scss']
})
export class ZeusUsabilityComponent implements OnInit {

  @Output() goToForm = new EventEmitter();


  onClickOnGoToForm(){
    this.goToForm.emit();
  }
  
  public usabilityArray = [
    {
      title : "Scalabilità",
      description : "Scegli da dove partire e dove arrivare."
    },
    {
      title : "Semplicità",
      description : "Utilizzo intuitivo e adatto ad ogni livello di competenza."
    },
    {
      title : "Praticità",
      description : "Accessibilità in qualsiasi momento e ovunque tu voglia."
    },
    {
      title : "Customizzazione",
      description : "I tuoi contenuti unici."
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
