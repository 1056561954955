import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ZeusContactBoxComponent } from './zeus-contact-box/zeus-contact-box.component';

@Component({
  selector: 'app-zeus-functionality',
  templateUrl: './zeus-functionality.component.html',
  styleUrls: ['./zeus-functionality.component.scss']
})

export class ZeusFunctionalityComponent implements OnInit {
  @Input() zeusBox = ZeusContactBoxComponent;
  @Output() goToForm = new EventEmitter();


  onClickOnGoToForm(){
    this.goToForm.emit();
  }

  public functionalityArray = [
    {
      image: "./assets/Icons/alloggi.svg",
      title: "ACCOMODATIONS",
      description: "Utilizza un database con oltre 38.000 strutture in tutto il mondo preinserite per te, oppure inseriscine una in totale autonomia.",
      link : "#"
    },
    {
      image: "./assets/Icons/trasporti.svg",
      title: "TRANSPORTS",
      description: "Inserisci qualsiasi operativo: Voli, Nave, Treno, Bus. Non ci sono limiti alle modalità di trasferimento che puoi proporre.",
      link : "#"
    },
    {
      image: "./assets/Icons/esperienze.svg",
      title: "EXPERIENCE",
      description: " Offri ogni ogni tipo di esperienza. Organizza e fai esplorare le attività ideate per i tuoi viaggiatori, e correla ogni singola esperienza ai tuoi tour.",
      link : "#"
    },
    {
      image: "./assets/Icons/tour.svg",
      title: "TOURS",
      description: "Proponi il tuo pacchetto dinamico creando tour 'tailor-made', tappa dopo tappa.",
      link : "#"
    },
    {
      image: "./assets/Icons/zeus_purple.svg",
      title: "ANCILLARY",
      description: "inserisci i tuoi servizi accessori, assicurazioni e politiche di cancellazione che completeranno il tuo prodotto.",
      link : "#"
    },
    {
      image: "./assets/Icons/modulo_layout.svg",
      title: "WEBSITE CONTENT",
      description: "Aggiorna la tua Homepage, personalizzala con i tuoi contenuti editoriali e crea pagine web e news.",
      link : "#"
    },
    {
      image: "./assets/Icons/quotazioni.svg",
      title: "QUOTATION",
      description: "Gestisci i preventivi e le prenotazioni che genera il tuo sito. Guarda le statistiche sul fatturato on-line.",
      link : "#"
    },
    {
      image: "./assets/Icons/pricing.svg",
      title: "PRICING",
      description: "Crea e proponi la tua offerta dinamica per mostrare una vacanza completa, chiara e prenotabile. Con i tuoi allotment e le fasce d’età per ogni soluzione.",
      link : "#"
    },
    {
      image: "./assets/Icons/voucher.svg",
      title: "VOUCHER",
      description: "Crea i tuoi voucher sconto e propagali grazie alla generazione di un codice univoco.",
      link : "#"
    },
  ]

  constructor() { }


  ngOnInit(): void {
  }

}
