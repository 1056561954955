import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-zeus-container',
  templateUrl: './zeus-container.component.html',
  styleUrls: ['./zeus-container.component.scss']
})
export class ZeusContainerComponent implements OnInit {

  @ViewChild('detailHeader') detailHeader: ElementRef;

  @ViewChild('zeusJumbotron') public zeusJumbotron: ElementRef<any>;
  @ViewChild('zeusDescription') public zeusDescription: ElementRef<any>;
  @ViewChild('zeusFunctionality') public zeusFunctionality: ElementRef<any>;
  @ViewChild('zeusIntegrations') public zeusIntegrations: ElementRef<any>;
  @ViewChild('zeusPricing') public zeusPricing: ElementRef<any>;
  @ViewChild('zeusUsability') public zeusUsability: ElementRef<any>;
  @ViewChild('zeusFeatures') public zeusFeatures: ElementRef<any>;
  @ViewChild('zeusForm') public zeusForm: ElementRef<any>;

  constructor() { }

  public scrollToTab(tab: 'jumbotron' | 'description' | 'functionality' | 'integrations' | 'pricing' | 'usability' | 'features' | 'form' ) {
    console.log(tab)
    let elementToScrollTo: any = null;
    switch (tab) {
      case 'jumbotron':
        elementToScrollTo = this.zeusJumbotron.nativeElement;
      break;
      case 'description':
        elementToScrollTo = this.zeusDescription.nativeElement;
      break;
      case 'functionality':
        elementToScrollTo = this.zeusFunctionality.nativeElement;
      break;
      case 'integrations':
        elementToScrollTo = this.zeusIntegrations.nativeElement;
        break;
      case 'pricing':
        elementToScrollTo = this.zeusPricing.nativeElement;
        break;
      case 'usability':
        elementToScrollTo = this.zeusUsability.nativeElement;
        break;
      case 'features':
        elementToScrollTo = this.zeusFeatures.nativeElement;
        break;
        case 'form':
        elementToScrollTo = this.zeusForm.nativeElement;
        break;
      default:
        break;
    }

    console.log(elementToScrollTo)

    setTimeout(()=>{
      const elementPositionY = elementToScrollTo.getBoundingClientRect().top;
      window.scrollTo({
        top: elementPositionY + window.scrollY - this.detailHeader.nativeElement.clientHeight,
        behavior: 'smooth'
      });  
    }, 50)
  }

  ngOnInit(): void {
  }

}
