import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmailParams } from '../interfaces/iemail-params';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  baseURL = 'https://api.offertetouroperator.com';
  apiKey = 'db7828797eca9b2d01a7b56f1d0c900468b0ca34';

  constructor(private _http: HttpClient) { }

  public sendMail(mailObj: IEmailParams) {
    const url = `${this.baseURL}/index.php/sendemail/${this.apiKey}`;  //NB usa api di produzione
    return this._http.post<any>(url, mailObj).toPromise();
  }
}
