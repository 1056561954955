import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-pricing',
  templateUrl: './zeus-pricing.component.html',
  styleUrls: ['./zeus-pricing.component.scss']
})
export class ZeusPricingComponent implements OnInit {

  @Output() goToForm = new EventEmitter();


  onClickOnGoToForm(){
    this.goToForm.emit();
  }

  public modules = [
    "+ Web Service output",
    "+ Blog",
    "+ Support (assistenza)",
    "+ SEO",
    "+ Account Zeus CMS (licenze)",
    "+ Liste in Viaggio",
    "+ Module entry groupage Cruise",
    "+ Area Riservata Trade",
    "+ Area Riservata Consumer",
    "+ Plugin Cruise Operator",
    "+ Plugin Tour Operator",
    "+ Module Payments" 
  ]
  public pricingArray = [
    {
      title : "STARTER Plan",
      price : "€88",
      setup: "SETUP: €1299",
      description: "La soluzione perfetta per Agenzie di Viaggio e Consulenti",
      cta: "Prova subito",
      class: "-",
      features : [
        "Sito web a sceta tra 2 template",
        "Hosting",
        "Storage API",
        "Maintenance",
        "Modulo Home",
        "Modulo Accomodation",
        "Modulo Transports",
        "Modulo Pricing",
        "Modulo Quotations",
        "Training CMS (2h)",
        "-",
        "-",
        "-"
      ],
    },
    {
      title : "PRO Plan",
      price : "su richiesta",
      setup: "SETUP: su richiesta",
      description: "La soluzione per Organizzatori di Viaggio e Tour Operator.",
      cta: "Prova subito",
      class: "pro",
      features : [
        "Sito web a sceta tra 2 template",
        "Hosting",
        "Storage API",
        "Maintenance",
        "Modulo Home",
        "Modulo Accomodation",
        "Modulo Transports",
        "Modulo Pricing",
        "Modulo Quotations",
        "Modulo Tour",
        "Modulo Experience",
        "Modulo Product",
        "Training CMS (2h)",
      ],
    },
    {
      title : "EXPERT Plan",
      price : "su richiesta",
      setup: "SETUP: su richiesta",
      description: "La soluzione costruita su misura per il tuo business.",
      cta: "Chiedi informazioni",
      class: "-",
      features : [
        "Sito web a sceta tra 2 template",
        "Hosting",
        "Storage API",
        "Maintenance",
        "Modulo Home",
        "Modulo Accomodation",
        "Modulo Transports",
        "Modulo Pricing",
        "Modulo Quotations",
        "Modulo Tour",
        "Modulo Experience",
        "Modulo Product",
        "Modulo Voucher",
      ],
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
