import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent implements OnInit {

  newMailValue:string = '';

  @Output() emitMailValue = new EventEmitter();
  @Output() goToForm = new EventEmitter();

  onClickOnGoToForm(){
    this.goToForm.emit();
  }

  
  public getMailValue( mailValue:string ){
    this.newMailValue = mailValue;
    this.emitMailValue.emit(this.newMailValue);
    this.newMailValue = '';
  }

  

  constructor() { }

  ngOnInit(): void {
  }

}
