import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-integrations',
  templateUrl: './zeus-integrations.component.html',
  styleUrls: ['./zeus-integrations.component.scss']
})
export class ZeusIntegrationsComponent implements OnInit {

  @Output() goToForm = new EventEmitter();

  onClickOnGoToForm(){
    this.goToForm.emit();
  }


  constructor() { }

  ngOnInit(): void {
  }

}
