import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-features',
  templateUrl: './zeus-features.component.html',
  styleUrls: ['./zeus-features.component.scss']
})
export class ZeusFeaturesComponent implements OnInit {

  @Output() goToForm = new EventEmitter();


  onClickOnGoToForm(){
    this.goToForm.emit();
  }


  constructor() { }

  ngOnInit(): void {
  }

}
