import { Component, OnInit,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zeus-contact-box',
  templateUrl: './zeus-contact-box.component.html',
  styleUrls: ['./zeus-contact-box.component.scss']
})
export class ZeusContactBoxComponent implements OnInit {

  @Output() goToForm = new EventEmitter();

  onClickOnGoToForm(){
    this.goToForm.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
