import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZeusContainerComponent } from './zeus-container/zeus-container.component';
import { ZeusPrivacyComponent } from './zeus-privacy/zeus-privacy.component';

const routes: Routes = [
  {path: '', redirectTo:'home', pathMatch:'full'},
  {path: 'privacy', component: ZeusPrivacyComponent},
  {path: 'home', component: ZeusContainerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
