import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  title = 'Zeus - CMS ';



  public inputMailValue : string = '';

  constructor (private router: Router){}



  receiveMailValue(newItem: string) {
    this.inputMailValue = newItem;
  }

  hasRoute(route:string){
    return this.router.url.includes(route);
  }
}
