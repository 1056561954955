import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IEmailParams } from '../interfaces/iemail-params';
import { MailerService } from '../services/mailer.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-zeus-form',
  templateUrl: './zeus-form.component.html',
  styleUrls: ['./zeus-form.component.scss']
})
export class ZeusFormComponent implements OnInit {
  
  @Input() public mailValue : any;

  userName: string = '';
  userSurname: string = '';
  userPhone: string = '';
  userNote: string = '';
  userTerms: boolean = false;
  sendingEmail: boolean = false;

  public highlightErrors: boolean = false;
  public alreadySentMail: boolean = false;
  public emailSent: boolean = false;

  constructor(private _mailerService: MailerService) { }

  ngOnInit(): void {
  }

  public getMail(){
    let mailMessage = `<strong>${this.userName}&nbsp;${this.userSurname}</strong> 
                         ha inviato una richiesta di demo per ZEUS tramite il form contattaci
                         <p>Dati per ricontattare l'utente:</p> <p>Email: ${this.mailValue}</p>
                         `
                         if(this.userPhone){
                          mailMessage += `<p>Telefono: ${this.userPhone}</p>`
                         }
    return mailMessage;
  }
  
  public resetFormFields(){
    this.userName = '';
    this.userSurname = '';
    this.mailValue = '';
    this.userPhone = '';
    this.userNote = '';
    this.userTerms = false;
  }

  public openSweetAlert(){
    Swal.fire({
      title: 'Congratulazioni!',
      text: 'Grazie per aver inviato la tua richiesta di demo, verrai ricontattato a breve dal nostro Team.',
      icon: 'success',
      confirmButtonText: 'Prosegui',
      confirmButtonColor: '#6191e2',
    })
  }

  public confirmMessage(){
    setTimeout(this.openSweetAlert,500)
  }

  

  public getMailObject(){

    if(this.sendingEmail){
      return
    } 

    if(!this.userName.length || !this.userSurname || !this.mailValue){
      this.highlightErrors = true;
      return 
    }

    this.highlightErrors = false;
    this.sendingEmail = true;

    const mailObj: IEmailParams = {
      title: 'Richiesta Demo - Zeus CMS',
      body: this.getMail(),
      from: this.mailValue,
      to: "assistenza@otosrl.com",
    };

    this._mailerService.sendMail(mailObj)
    .then( (res)=> {
                    this.resetFormFields(),
                    this.emailSent = true; 
                    })
    .catch((err) => console.log(err))
    .finally(()=>  {
                    this.confirmMessage(),
                    this.sendingEmail = false,
                    this.alreadySentMail = true;
                    }); 
  }
  
  
}
