import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { ZeusDescriptionComponent } from './zeus-description/zeus-description.component';
import { ZeusFunctionalityComponent } from './zeus-functionality/zeus-functionality.component';
import { ZeusContactBoxComponent } from './zeus-functionality/zeus-contact-box/zeus-contact-box.component';
import { ZeusUsabilityComponent } from './zeus-usability/zeus-usability.component';
import { ZeusPricingComponent } from './zeus-pricing/zeus-pricing.component';
import { ZeusIntegrationsComponent } from './zeus-integrations/zeus-integrations.component';
import { ZeusFeaturesComponent } from './zeus-features/zeus-features.component';
import { ZeusFormComponent } from './zeus-form/zeus-form.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ZeusTrustComponent } from './zeus-trust/zeus-trust.component';
import { ZeusServicesComponent } from './zeus-services/zeus-services.component';
import { ZeusPrivacyComponent } from './zeus-privacy/zeus-privacy.component';
import { ZeusContainerComponent } from './zeus-container/zeus-container.component';


@NgModule({
  declarations: [
    AppComponent,
    JumbotronComponent,
    ZeusDescriptionComponent,
    ZeusFunctionalityComponent,
    ZeusContactBoxComponent,
    ZeusUsabilityComponent,
    ZeusPricingComponent,
    ZeusIntegrationsComponent,
    ZeusFeaturesComponent,
    ZeusFormComponent,
    ZeusTrustComponent,
    ZeusServicesComponent,
    ZeusPrivacyComponent,
    ZeusContainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
